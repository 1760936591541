// NotificationsPage.tsx
import React, { useEffect, useState } from 'react';
import { fadeTimeout, getOverallPadding } from '../helpers/Themes';
import { Button, CardContent, Divider, Fade, Typography } from '@mui/material';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { Meta, Notification, User } from '../workers/ApiWorker';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApi } from '../contexts/ApiContext';
import { extractNotificationData } from '../helpers/Notifiations';
import NotificationBody from '../components/notification/NotificationBody';
import PageTopSection from '../components/universal/pageTopSection/PageTopSection';
import Card from '@mui/material/Card';

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

interface NotificationsPageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
}

const NotificationsPage: React.FC<NotificationsPageProps> = ({
    isMobile,
    padding,
    user,
}) => {
    const location = useLocation();
    const apiWorker = useWorker(createApiWorker);
    const { token } = useApi();
    const navigate = useNavigate();

    const [loadingResults, setLoadingResults] = useState<boolean>(true);
    const [usersNotifications, setUsersNotifications] = useState<
        Notification[]
    >([]);
    const [usersNotificationMeta, setUsersNotificationMeta] = useState<Meta>();
    const [userNotificationTotal, setUsersNotificationTotal] =
        useState<number>();

    const defaultPage = 1;
    const defaultCount = 5;
    const [currentCount, setCurrentCount] = useState<number>(defaultCount);

    const handleLoadMore = async () => {
        await getNotifications(currentCount + defaultCount);
    };

    const handleRefresh = async () => {
        await getNotifications(currentCount);
    };

    const handleClearAll = async () => {
        if (token) {
            setLoadingResults(true);
            try {
                const response = await apiWorker.deleteAllNotifications(token);

                setUsersNotifications([]);
                setUsersNotificationMeta(undefined);
                setUsersNotificationTotal(0);
            } catch (error) {
                console.log('Error deleting notifications');
            }
            setLoadingResults(false);
        }
    };

    // Function to fetch notifications
    const getNotifications = async (newCount?: number) => {
        if (token) {
            setLoadingResults(true);
            try {
                const response = await apiWorker.getNotifications(token, {
                    count: newCount ? newCount : currentCount,
                    page: defaultPage,
                });

                if (newCount) {
                    setCurrentCount(newCount);
                }
                setUsersNotifications(response.data);
                setUsersNotificationMeta(response.meta);
                setUsersNotificationTotal(response.meta.items.total);
            } catch (error) {
                console.log('Error getting notifications');
            }
            setLoadingResults(false);
        }
    };

    useEffect(() => {
        getNotifications()
            .then(() => {})
            .catch((error) => {
                console.log('Error fetching notifications:', error);
            });
    }, [location]);

    const handleMarkNotificationAsRead = async (
        notificationUuid: string,
        link?: string
    ) => {
        if (token) {
            try {
                await apiWorker.deleteNotification(notificationUuid, token);

                setUsersNotifications((prevNotifications) => {
                    const updatedNotifications = prevNotifications.filter(
                        (n) => n.uuid !== notificationUuid
                    );

                    setUsersNotificationTotal((prevTotal) => {
                        const newTotal = prevTotal ? prevTotal - 1 : 0;
                        return newTotal < 0 ? 0 : newTotal;
                    });

                    return updatedNotifications;
                });

                if (link) {
                    navigate(link);
                }
            } catch (error) {
                console.warn('Could not mark notification as read', error);
            }
        }
    };

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Box sx={{ position: 'relative', overflow: 'hidden', padding: 2 }}>
                <Box
                    sx={{
                        paddingLeft: getOverallPadding(isMobile, padding),
                        paddingRight: getOverallPadding(isMobile, padding),
                    }}
                >
                    <PageTopSection
                        isMobile={isMobile}
                        padding={padding}
                        hideSeperator={true}
                        gapSize={'small'}
                    />
                    {!loadingResults ? (
                        <Fade in={true} timeout={fadeTimeout}>
                            <div>
                                <Card
                                    sx={{
                                        marginBottom: 5,
                                    }}
                                >
                                    <CardContent
                                        sx={{
                                            marginTop: -2,
                                        }}
                                    >
                                        {!userNotificationTotal ||
                                        userNotificationTotal === 0 ? (
                                            <Typography
                                                sx={{
                                                    cursor: 'default',
                                                    marginTop: 3,
                                                }}
                                            >
                                                You have no new Notifications
                                            </Typography>
                                        ) : usersNotifications &&
                                          usersNotifications.length > 0 ? (
                                            <>
                                                <Button
                                                    sx={{
                                                        marginTop: 3,
                                                    }}
                                                    variant="outlined"
                                                    color="warning"
                                                    type="submit"
                                                    onClick={async () => {
                                                        await handleClearAll();
                                                    }}
                                                >
                                                    Clear All Notifications
                                                </Button>
                                                {usersNotifications.map(
                                                    (
                                                        userNotification,
                                                        index
                                                    ) => {
                                                        // Extract relevant notification data
                                                        const extractedData =
                                                            extractNotificationData(
                                                                userNotification
                                                            );

                                                        return (
                                                            <>
                                                                <Box
                                                                    sx={{
                                                                        marginBottom:
                                                                            index <
                                                                            usersNotifications.length -
                                                                                1
                                                                                ? 3
                                                                                : 1,
                                                                        marginTop: 3,
                                                                    }}
                                                                >
                                                                    <NotificationBody
                                                                        key={
                                                                            index
                                                                        }
                                                                        additionalContent={
                                                                            extractedData.additionalContent
                                                                        }
                                                                        createdAt={
                                                                            userNotification.createdAt
                                                                        }
                                                                        isPage={
                                                                            true
                                                                        }
                                                                        notification={
                                                                            userNotification
                                                                        }
                                                                        handleMarkNotificationAsRead={
                                                                            handleMarkNotificationAsRead
                                                                        }
                                                                        link={
                                                                            extractedData.link
                                                                        }
                                                                    />
                                                                </Box>
                                                                {index <
                                                                    usersNotifications.length -
                                                                        1 && (
                                                                    <Divider />
                                                                )}
                                                            </>
                                                        );
                                                    }
                                                )}
                                                {/* Show 'Load More' if total notifications are more than 5 */}
                                                {userNotificationTotal >
                                                    currentCount && (
                                                    <Button
                                                        sx={{
                                                            marginTop: 3,
                                                        }}
                                                        variant="outlined"
                                                        color="warning"
                                                        type="submit"
                                                        disabled={
                                                            loadingResults
                                                        }
                                                        onClick={async () => {
                                                            await handleLoadMore();
                                                        }}
                                                    >
                                                        Load More
                                                    </Button>
                                                )}
                                            </>
                                        ) : (
                                            <Button
                                                sx={{
                                                    marginTop: 3,
                                                }}
                                                variant="contained"
                                                color="warning"
                                                type="submit"
                                                disabled={loadingResults}
                                                onClick={async () => {
                                                    await handleRefresh();
                                                }}
                                            >
                                                Load More
                                            </Button>
                                        )}
                                    </CardContent>
                                </Card>
                            </div>
                        </Fade>
                    ) : null}
                </Box>
            </Box>
        </Fade>
    );
};

export default NotificationsPage;
