import React, { useEffect, useRef, useState } from 'react';
import { Typography, Button, Stack, LinearProgress } from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import {
    downloadTypeOptions,
    earlyAccessReleaseAddedDays,
    Game,
    standardReleaseAddedDays,
    UpdateUploadBasicProps,
    UpdateUploadProps,
    Upload,
} from '../../workers/ApiWorker';
import CustomModal from '../universal/modals/CustomModal';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextInput from '../universal/inputs/TextInput';
import RichTextInput from '../universal/inputs/RichTextInput';
import TagInput from '../universal/inputs/TagInput';
import ImageUpload from '../universal/images/ImageUploads';
import Box from '@mui/material/Box';
import { AxiosProgressEvent } from 'axios';
import {
    CREATE_UPLOAD_VERSION_FAILED,
    UPDATE_UPLOAD_FAILED,
    UPDATE_UPLOAD_GET_IMAGE_FAILED,
    UPLOAD_IMAGE_FAILED,
} from '../../helpers/Messages';
import { useApi } from '../../contexts/ApiContext';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import ErrorAlert from '../universal/alerts/ErrorAlert';
import RadioInput from '../universal/inputs/RadioInput';
import DateTimeInput from '../universal/inputs/DateTimeInput';
import MultiSelectInput from '../universal/inputs/MultiSelectInput';

interface UpdateUploadModalProps {
    closeModal: (resetForm?: () => void) => void;
    setError: (error: string | undefined) => void;
    error?: string;
    isModalOpen: boolean;
    isMobile: boolean;
    upload: Upload;
    setUpload: (upload: Upload) => void;
    setSelectedVersionIndex: (versionIndex: number) => void;
}

const createApiWorker = createWorkerFactory(
    () => import('../../workers/ApiWorker')
);

const UpdateUploadModal: React.FC<UpdateUploadModalProps> = ({
    isMobile,
    closeModal,
    setError,
    isModalOpen,
    error,
    upload,
    setUpload,
    setSelectedVersionIndex,
}) => {
    const { token, uploadTypes, logout } = useApi();
    const apiWorker = useWorker(createApiWorker);

    const [switchCheckedPublic, setSwitchCheckedPublic] =
        React.useState<boolean>(false);
    const [switchCheckedPrivate, setSwitchCheckedPrivate] =
        React.useState<boolean>(false);

    const [games, setGames] = useState<Game[] | null>(null);

    useEffect(() => {
        const getGames = async () => {
            //    setLoadingGames(true);
            const response = await apiWorker.getGames();
            setGames(response.data);
            //    setLoadingGames(false);
        };
        getGames().catch(console.error);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Validation Schema
    const validationSchemaVersion = Yup.object({
        selectedDownloadLinkType: Yup.string().required(
            'Please select a Download Type'
        ),
        downloadLinkPublic: Yup.string()
            .url('Public Download Link must be a valid URL')
            .test(
                'download-link-public-required',
                'Public Download Link is required if the selected download type is Public or Early Access Patreon',
                function (value) {
                    if (
                        this.parent.selectedDownloadLinkType === 'public' ||
                        this.parent.selectedDownloadLinkType === 'both'
                    ) {
                        if (!value) return false;
                    }
                    return true;
                }
            )
            .test(
                'download-link-public-no-patreon-links',
                'Public Download Link cannot be a Patreon link',
                function (value) {
                    if (
                        this.parent.selectedDownloadLinkType === 'public' ||
                        this.parent.selectedDownloadLinkType === 'both'
                    ) {
                        if (value && value.toLowerCase().includes('patreon')) {
                            return false; // Fail validation if public is true and Patreon is in the link
                        }
                    }
                    return true;
                }
            ),
        downloadLinkPrivate: Yup.string()
            .url('Patreon Download Link must be a valid URL')
            .test(
                'download-link-private-required',
                'Patreon Download Link is required if the selected download type is Patreon Exclusive or Early Access Patreon',
                function (value) {
                    if (
                        this.parent.selectedDownloadLinkType === 'private' ||
                        this.parent.selectedDownloadLinkType === 'both'
                    ) {
                        if (!value) return false;
                    }
                    return true;
                }
            )
            .test(
                'download-link-private-only-patreon-links',
                'Patreon Download Link must be a Patreon link to a post. The link must start with https://www.patreon.com/posts/',
                function (value) {
                    if (
                        this.parent.selectedDownloadLinkType === 'private' ||
                        this.parent.selectedDownloadLinkType === 'both'
                    ) {
                        if (
                            value &&
                            !/^https:\/\/www\.patreon\.com\/posts\/.+/.test(
                                value.toLowerCase()
                            )
                        ) {
                            return false; // Fail validation if public is true and Patreon is in the link
                        }
                    }
                    return true;
                }
            ),
        scheduledAtPublic: Yup.string()
            .nullable()
            .test('scheduled-at-public-both-required', '', function (value) {
                if (this.parent.selectedDownloadLinkType === 'both') {
                    if (!value) {
                        return false;
                    }
                }
                return true;
            })
            .test(
                'scheduled-at-public-both',
                'Public Release Date must set after the Patreon Exclusive Date',
                function (value) {
                    if (this.parent.selectedDownloadLinkType === 'both') {
                        const parentScheduledAtPrivate =
                            this.parent.scheduledAtPrivate;

                        if (value !== undefined && value !== null) {
                            if (parentScheduledAtPrivate) {
                                const valueDate = new Date(value);
                                const parentScheduledAtPrivateDate = new Date(
                                    parentScheduledAtPrivate
                                );

                                // Check if the value is less than or equal to parentScheduledAtPrivate
                                if (valueDate <= parentScheduledAtPrivateDate) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                }
            ),
    });

    const validationSchemaData = Yup.object({
        name: Yup.string()
            .required('Title is required')
            .max(25, 'Caption must be at most 25 characters'),
        videoUrl: Yup.string().matches(
            /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=[\w-]+|youtu\.be\/(?!@)[\w-]+)/,
            'Must be a valid YouTube URL: youtube.com/watch?v=video_id or youtu.be/video_id)'
        ), // Ensures it starts with http/https and matches YouTube domains
        miniDescription: Yup.string()
            .required('Caption is required')
            .max(45, 'Caption must be at most 45 characters'),
        description: Yup.string().required('Upload Description is required'),
        tags: Yup.array()
            .min(1, 'At least one Tag is required')
            .test(
                'alphanumeric',
                'Each tag must only contain letters (A-Z), numbers (0-9), and spaces ( )',
                (tags) => {
                    // Check if any tag contains non-alphanumeric characters
                    if (tags && tags.length > 0) {
                        return tags.every((tag) => /^[a-zA-Z0-9 ]+$/.test(tag)); // Updated regex to allow spaces
                    }
                    return true; // return true if tags are not provided or empty (min() check will handle non-empty requirement)
                }
            ),
        games: Yup.array().min(1, 'At least one Game is required'),
    });

    const width = '75%';
    const { theme } = useCustomTheme();
    const [selectedOption, setSelectedOption] = React.useState<string | null>(
        null
    );
    const [success, setSuccess] = React.useState<string | undefined>(undefined);
    const [uploadImage, setUploadImage] = useState<File | undefined>(undefined);
    const [submittedUploadImage, setSubmittedUploadImage] =
        useState<boolean>(false);
    const [progress, setProgress] = React.useState<number>(0);

    const handleImageUpload = async (value?: File) => {
        setUploadImage(value);
    };

    const [uploadDataBasicDetails, setUploadDataBasicDetails] =
        React.useState<UpdateUploadBasicProps>({
            name: upload.name,
            miniDescription: upload.miniDescription,
            description: upload.description,
            videoUrl: upload.videoUrl ? upload.videoUrl : '',
            tags: upload.tags,
            games: upload.games.map((game) => game.uuid),
        });

    // Options array with labels and values
    const options = [
        { label: 'ADD VERSION', value: 'version' },
        { label: 'UPDATE CONTENT', value: 'data' },
        { label: 'UPDATE IMAGE', value: 'image' },
    ];

    const [buttonWidth, setButtonWidth] = useState(0);

    // Create a ref for each button, typed as HTMLButtonElement or null
    const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);

    useEffect(() => {
        // Measure the width of the longest button
        const buttonWidths = buttonRefs.current.map(
            (button) => button?.offsetWidth || 0
        );
        const maxWidth = Math.max(...buttonWidths);
        setButtonWidth(maxWidth); // Set the max width for all buttons
    }, [options]); // Recalculate when options change

    const handleSubmittedImageUpload = async () => {
        setSubmittedUploadImage(true);
        setError(undefined);
        setProgress(0);

        if (token) {
            if (uploadImage) {
                let updateUpload = undefined;
                let createImage = undefined;

                try {
                    createImage = await apiWorker.postImage(
                        {
                            uuid: upload.uuid,
                            type: 'main',
                            scope: 'uploads',
                            image: uploadImage,
                        },
                        token,
                        (progressEvent: AxiosProgressEvent) => {
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) /
                                    (progressEvent.total ??
                                        progressEvent.loaded)
                            );
                            setProgress(percentCompleted);
                        }
                    );
                } catch (error) {
                    setError(UPLOAD_IMAGE_FAILED);
                    setSubmittedUploadImage(false);
                }

                if (createImage) {
                    try {
                        const updatedUpload = await apiWorker.updateUpload(
                            {
                                imageMain: createImage.data.key,
                            },
                            token,
                            upload.uuid
                        );

                        setUpload(updatedUpload.data);
                        handleCloseOptionModal();
                    } catch (error) {
                        setError(UPDATE_UPLOAD_FAILED);
                        setSubmittedUploadImage(false);
                    }
                }
            } else {
                setError(UPDATE_UPLOAD_GET_IMAGE_FAILED);
                setSubmittedUploadImage(false);
            }
        }
    };

    const handleCloseModal = () => {
        setSuccess(undefined);
        setError(undefined);
        setSelectedOption(null);
        setSubmittedUploadImage(false);
        setUploadImage(undefined);
        setProgress(0);
        closeModal();
    };

    const handleCloseOptionModalToMainMenu = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setSuccess(undefined);
        setError(undefined);
        setSelectedOption(null);
        setSubmittedUploadImage(false);
        setUploadImage(undefined);
        setProgress(0);
    };

    const handleCloseOptionModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setSuccess(undefined);
        setError(undefined);
        setSelectedOption(null);
        setSubmittedUploadImage(false);
        setUploadImage(undefined);
        setProgress(0);
        closeModal();
    };

    return (
        <>
            {!selectedOption ? (
                <CustomModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    isMobile={isMobile}
                    width={width}
                >
                    <Stack spacing={2}>
                        <Typography
                            variant="h3"
                            sx={{
                                marginBottom: 3,
                            }}
                            color={theme.palette.text.secondary}
                        >
                            UPDATE {upload.name.toUpperCase()}
                        </Typography>

                        {/* Box container with fixed width and centered content */}
                        <Stack
                            spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Box>
                                {options.map(({ label, value }) => (
                                    <Button
                                        key={value}
                                        variant="contained"
                                        onClick={() => setSelectedOption(value)}
                                        fullWidth // Make the buttons full-width within the container
                                        sx={{
                                            marginBottom: 2, // Space between buttons
                                        }}
                                    >
                                        {label}
                                    </Button>
                                ))}
                            </Box>
                        </Stack>
                    </Stack>
                </CustomModal>
            ) : (
                (() => {
                    switch (selectedOption) {
                        case 'version':
                            return (
                                <Formik
                                    initialValues={{
                                        downloadLinkPrivate: '',
                                        downloadLinkPublic: '',
                                        scheduledAtPublic: undefined,
                                        scheduledAtPrivate: undefined,
                                        selectedDownloadLinkType: undefined,
                                    }}
                                    validationSchema={validationSchemaVersion}
                                    enableReinitialize
                                    onSubmit={async (values, { resetForm }) => {
                                        setError(undefined);

                                        if (!token) {
                                            return;
                                        }

                                        try {
                                            const updatedUpload =
                                                await apiWorker.postUploadVersion(
                                                    values,
                                                    token,
                                                    upload.uuid
                                                );

                                            setUpload(updatedUpload.data);
                                            setSelectedVersionIndex(
                                                updatedUpload.data.versions
                                                    .length - 1
                                            );
                                            handleCloseOptionModal(resetForm);
                                        } catch (error) {
                                            setError(
                                                CREATE_UPLOAD_VERSION_FAILED
                                            );
                                        }
                                    }}
                                >
                                    {({
                                        errors,
                                        touched,
                                        values,
                                        handleChange,
                                        isSubmitting,
                                        handleBlur,
                                        isValid,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                    }) => (
                                        <CustomModal
                                            isOpen={isModalOpen}
                                            onClose={() => {
                                                handleCloseOptionModalToMainMenu();
                                            }}
                                            cancelLabel={'Return'}
                                            onConfirm={handleSubmit}
                                            isSubmitting={isSubmitting}
                                            isValid={isValid}
                                            isMobile={isMobile}
                                            width={width}
                                            confirmLabel={'Submit'}
                                        >
                                            <Typography
                                                variant="h3"
                                                sx={{
                                                    marginBottom: 3,
                                                }}
                                                color={
                                                    theme.palette.text.secondary
                                                }
                                            >
                                                ADD VERSION{' '}
                                                {upload.versions.length + 1} TO{' '}
                                                {upload.name.toUpperCase()}
                                            </Typography>
                                            {error && (
                                                <ErrorAlert
                                                    message={error}
                                                ></ErrorAlert>
                                            )}
                                            <RadioInput
                                                id={'selectedDownloadLinkType'}
                                                label={'DOWNLOAD TYPE *'}
                                                items={downloadTypeOptions.map(
                                                    (item) => ({
                                                        id: item.value,
                                                        value: item.value,
                                                        name: item.label,
                                                        caption: item.caption,
                                                    })
                                                )}
                                                value={
                                                    values.selectedDownloadLinkType
                                                }
                                                handleChange={setFieldValue}
                                                hasSubmitted={isSubmitting}
                                                errors={
                                                    errors.selectedDownloadLinkType
                                                }
                                                touched={
                                                    touched.selectedDownloadLinkType
                                                }
                                            />

                                            {values.selectedDownloadLinkType ===
                                            'private' ? (
                                                <>
                                                    <TextInput
                                                        id={
                                                            'downloadLinkPrivate'
                                                        }
                                                        label={
                                                            'PATREON DOWNLOAD LINK *'
                                                        }
                                                        value={
                                                            values.downloadLinkPrivate
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        handleBlur={handleBlur}
                                                        hasSubmitted={
                                                            isSubmitting
                                                        }
                                                        touched={
                                                            touched.downloadLinkPrivate
                                                        }
                                                        errors={
                                                            errors.downloadLinkPrivate
                                                        }
                                                        isMobile={isMobile}
                                                        caption={
                                                            'Please provide a link which will direct users to the upload post on your Patreon.'
                                                        }
                                                    />
                                                    <DateTimeInput
                                                        switchChecked={
                                                            switchCheckedPrivate
                                                        }
                                                        setSwitchChecked={
                                                            setSwitchCheckedPrivate
                                                        }
                                                        id={
                                                            'scheduledAtPrivate'
                                                        }
                                                        label={
                                                            'SCHEDULE RELEASE'
                                                        }
                                                        handleChange={
                                                            setFieldValue
                                                        }
                                                        hasSubmitted={
                                                            isSubmitting
                                                        }
                                                        touched={
                                                            touched.scheduledAtPrivate
                                                        }
                                                        errors={
                                                            errors.scheduledAtPrivate
                                                        }
                                                        value={
                                                            values.scheduledAtPrivate
                                                        }
                                                        caption={
                                                            'Your upload will appear available on the selected date/time once approved.'
                                                        }
                                                        disablePast={true}
                                                        addDays={
                                                            standardReleaseAddedDays
                                                        }
                                                    />
                                                </>
                                            ) : null}

                                            {values.selectedDownloadLinkType ===
                                            'public' ? (
                                                <>
                                                    <TextInput
                                                        id={
                                                            'downloadLinkPublic'
                                                        }
                                                        label={
                                                            'PUBLIC DOWNLOAD LINK *'
                                                        }
                                                        value={
                                                            values.downloadLinkPublic
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        handleBlur={handleBlur}
                                                        hasSubmitted={
                                                            isSubmitting
                                                        }
                                                        touched={
                                                            touched.downloadLinkPublic
                                                        }
                                                        errors={
                                                            errors.downloadLinkPublic
                                                        }
                                                        isMobile={isMobile}
                                                        caption={
                                                            'Please provide a Direct Download Link.'
                                                        }
                                                    />
                                                    <DateTimeInput
                                                        switchChecked={
                                                            switchCheckedPublic ||
                                                            (values.scheduledAtPublic !==
                                                                undefined &&
                                                                values.scheduledAtPublic !==
                                                                    null)
                                                        }
                                                        setSwitchChecked={
                                                            setSwitchCheckedPublic
                                                        }
                                                        id={'scheduledAtPublic'}
                                                        touched={
                                                            touched.scheduledAtPublic
                                                        }
                                                        errors={
                                                            errors.scheduledAtPublic
                                                        }
                                                        label={
                                                            'SCHEDULE RELEASE'
                                                        }
                                                        handleChange={
                                                            setFieldValue
                                                        }
                                                        hasSubmitted={
                                                            isSubmitting
                                                        }
                                                        value={
                                                            values.scheduledAtPublic
                                                        }
                                                        caption={
                                                            'Your upload will appear available on the selected date/time once approved.'
                                                        }
                                                        disablePast={true}
                                                        addDays={
                                                            standardReleaseAddedDays
                                                        }
                                                    />
                                                </>
                                            ) : null}

                                            {values.selectedDownloadLinkType ===
                                            'both' ? (
                                                <>
                                                    <TextInput
                                                        id={
                                                            'downloadLinkPrivate'
                                                        }
                                                        label={
                                                            'PATREON DOWNLOAD LINK *'
                                                        }
                                                        value={
                                                            values.downloadLinkPrivate
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        handleBlur={handleBlur}
                                                        hasSubmitted={
                                                            isSubmitting
                                                        }
                                                        touched={
                                                            touched.downloadLinkPrivate
                                                        }
                                                        errors={
                                                            errors.downloadLinkPrivate
                                                        }
                                                        isMobile={isMobile}
                                                        caption={
                                                            'Please provide a link which will direct users to the upload post on your Patreon.'
                                                        }
                                                    />
                                                    <DateTimeInput
                                                        switchChecked={
                                                            switchCheckedPrivate
                                                        }
                                                        setSwitchChecked={
                                                            setSwitchCheckedPrivate
                                                        }
                                                        touched={
                                                            touched.scheduledAtPrivate
                                                        }
                                                        errors={
                                                            errors.scheduledAtPrivate
                                                        }
                                                        id={
                                                            'scheduledAtPrivate'
                                                        }
                                                        label={
                                                            'SCHEDULE RELEASE'
                                                        }
                                                        handleChange={
                                                            setFieldValue
                                                        }
                                                        hasSubmitted={
                                                            isSubmitting
                                                        }
                                                        value={
                                                            values.scheduledAtPrivate
                                                        }
                                                        caption={
                                                            'Your upload will appear available on the selected date/time once approved.'
                                                        }
                                                        disablePast={true}
                                                        addDays={
                                                            standardReleaseAddedDays
                                                        }
                                                    />
                                                    <TextInput
                                                        id={
                                                            'downloadLinkPublic'
                                                        }
                                                        label={
                                                            'PUBLIC DOWNLOAD LINK *'
                                                        }
                                                        value={
                                                            values.downloadLinkPublic
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        handleBlur={handleBlur}
                                                        hasSubmitted={
                                                            isSubmitting
                                                        }
                                                        touched={
                                                            touched.downloadLinkPublic
                                                        }
                                                        errors={
                                                            errors.downloadLinkPublic
                                                        }
                                                        isMobile={isMobile}
                                                        caption={
                                                            'Please provide a Direct Download Link.'
                                                        }
                                                    />
                                                    <DateTimeInput
                                                        enableToggle={false}
                                                        setSwitchChecked={
                                                            setSwitchCheckedPublic
                                                        }
                                                        id={'scheduledAtPublic'}
                                                        label={
                                                            'PUBLIC RELEASE DATE *'
                                                        }
                                                        touched={
                                                            touched.scheduledAtPublic
                                                        }
                                                        errors={
                                                            errors.scheduledAtPublic
                                                        }
                                                        handleChange={
                                                            setFieldValue
                                                        }
                                                        hasSubmitted={
                                                            isSubmitting
                                                        }
                                                        value={
                                                            values.scheduledAtPublic
                                                        }
                                                        caption={
                                                            'Your upload will be available to the public on the selected date/time once approved.'
                                                        }
                                                        disablePast={true}
                                                        minDate={
                                                            values.scheduledAtPrivate
                                                        }
                                                        addDays={
                                                            earlyAccessReleaseAddedDays
                                                        }
                                                    />
                                                </>
                                            ) : null}
                                        </CustomModal>
                                    )}
                                </Formik>
                            );
                        case 'image':
                            return (
                                <CustomModal
                                    isOpen={isModalOpen}
                                    onClose={() => {
                                        handleCloseOptionModalToMainMenu();
                                    }}
                                    cancelLabel={'Return'}
                                    isMobile={isMobile}
                                    width={width}
                                    confirmLabel={'Upload'}
                                    onConfirm={() => {
                                        handleSubmittedImageUpload();
                                    }}
                                    isValid={uploadImage !== undefined}
                                    isSubmitting={submittedUploadImage}
                                >
                                    <Typography
                                        variant="h3"
                                        sx={{
                                            marginBottom: 3,
                                        }}
                                        color={theme.palette.text.secondary}
                                    >
                                        UPDATE IMAGE FOR{' '}
                                        {upload.name.toUpperCase()}
                                    </Typography>
                                    {error && (
                                        <ErrorAlert
                                            message={error}
                                        ></ErrorAlert>
                                    )}
                                    {submittedUploadImage && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 2,
                                                marginBottom: 2,
                                            }}
                                        >
                                            <Box sx={{ flexGrow: 1 }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={progress}
                                                />
                                            </Box>
                                            <Box sx={{ minWidth: 35 }}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    {`${Math.round(progress)}%`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                    <ImageUpload
                                        uploadData={{
                                            title: upload.name,
                                            description: upload.miniDescription,
                                            type: upload.type,
                                            games: upload.games,
                                        }}
                                        file={uploadImage}
                                        handleImageUpload={handleImageUpload}
                                        disabled={submittedUploadImage}
                                    />
                                </CustomModal>
                            );
                        case 'data':
                            if (games === null) return null;

                            return (
                                <Formik
                                    initialValues={uploadDataBasicDetails}
                                    validationSchema={validationSchemaData}
                                    enableReinitialize
                                    onSubmit={async (values, { resetForm }) => {
                                        setError(undefined);

                                        if (!token) {
                                            return;
                                        }

                                        try {
                                            const updatedUpload =
                                                await apiWorker.updateUpload(
                                                    values,
                                                    token,
                                                    upload.uuid
                                                );

                                            setUpload(updatedUpload.data);
                                            setUploadDataBasicDetails({
                                                name: updatedUpload.data.name,
                                                miniDescription:
                                                    updatedUpload.data
                                                        .miniDescription,
                                                description:
                                                    updatedUpload.data
                                                        .description,
                                                videoUrl: updatedUpload.data
                                                    .videoUrl
                                                    ? updatedUpload.data
                                                          .videoUrl
                                                    : '',
                                                tags: updatedUpload.data.tags,
                                                games: updatedUpload.data.games.map(
                                                    (game) => game.uuid
                                                ),
                                            });
                                            handleCloseOptionModal(resetForm);
                                        } catch (error) {
                                            setError(UPDATE_UPLOAD_FAILED);
                                        }
                                    }}
                                >
                                    {({
                                        errors,
                                        touched,
                                        values,
                                        handleChange,
                                        isSubmitting,
                                        handleBlur,
                                        isValid,
                                        handleSubmit,
                                        setFieldValue,
                                        resetForm,
                                    }) => (
                                        <CustomModal
                                            isOpen={isModalOpen}
                                            onClose={() => {
                                                handleCloseOptionModalToMainMenu();
                                            }}
                                            cancelLabel={'Return'}
                                            onConfirm={handleSubmit}
                                            isSubmitting={isSubmitting}
                                            isValid={isValid}
                                            isMobile={isMobile}
                                            width={width}
                                            confirmLabel={'Update'}
                                        >
                                            <Typography
                                                variant="h3"
                                                sx={{
                                                    marginBottom: 3,
                                                }}
                                                color={
                                                    theme.palette.text.secondary
                                                }
                                            >
                                                UPDATE CONTENT FOR{' '}
                                                {upload.name.toUpperCase()}
                                            </Typography>
                                            {error && (
                                                <ErrorAlert
                                                    message={error}
                                                ></ErrorAlert>
                                            )}
                                            <MultiSelectInput
                                                items={games.map((item) => ({
                                                    id: item.uuid,
                                                    value: item.uuid,
                                                    name:
                                                        item.name +
                                                        ' (' +
                                                        item.system +
                                                        ')',
                                                }))}
                                                id={'games'}
                                                label={'GAME(S) *'}
                                                caption={
                                                    'Select one or more games. The first game selected will serve as the primary display.'
                                                }
                                                value={values.games}
                                                errors={errors.games}
                                                touched={touched.games}
                                                handleBlur={handleBlur}
                                                hasSubmitted={isSubmitting}
                                                handleChange={setFieldValue}
                                                isMobile={isMobile}
                                                sortByName={true}
                                            />
                                            <TextInput
                                                id={'name'}
                                                label={'TITLE *'}
                                                value={values.name}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                hasSubmitted={isSubmitting}
                                                touched={touched.name}
                                                errors={errors.name}
                                                isMobile={isMobile}
                                            />
                                            <TextInput
                                                id={'miniDescription'}
                                                label={'CAPTION *'}
                                                value={values.miniDescription}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                hasSubmitted={isSubmitting}
                                                touched={
                                                    touched.miniDescription
                                                }
                                                errors={errors.miniDescription}
                                                isMobile={isMobile}
                                                caption="The descriptive text visible in the thumbnail of the upload"
                                            />
                                            <RichTextInput
                                                id={'description'}
                                                label={'UPLOAD DESCRIPTION *'}
                                                value={values.description}
                                                errors={errors.description}
                                                handleBlur={handleBlur}
                                                hasSubmitted={isSubmitting}
                                                touchedFormik={
                                                    touched.description
                                                }
                                                handleChange={setFieldValue}
                                                isMobile={isMobile}
                                            />
                                            <TextInput
                                                id={'videoUrl'}
                                                label={'YOUTUBE'}
                                                caption={
                                                    'Must be a link to a video. Not your YouTube account!'
                                                }
                                                value={values.videoUrl}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                hasSubmitted={isSubmitting}
                                                touched={touched.videoUrl}
                                                errors={errors.videoUrl}
                                                isMobile={isMobile}
                                            />
                                            <TagInput
                                                id={'tags'}
                                                label={'TAGS *'}
                                                value={values.tags}
                                                handleChange={setFieldValue}
                                                handleBlur={handleBlur}
                                                hasSubmitted={isSubmitting}
                                                touched={touched.tags}
                                                errors={errors.tags}
                                                isMobile={isMobile}
                                                caption="Allowed: A-Z, 0-9, and spaces. Press Enter to add a tag."
                                            />
                                        </CustomModal>
                                    )}
                                </Formik>
                            );
                        default:
                            break;
                    }
                })()
            )}
        </>
    );
};

export default UpdateUploadModal;
