import {
    Upload,
    UploadStatus,
    UploadType,
    UploadTypeNames,
    User,
    UserAccountTypes,
    Version,
} from '../workers/ApiWorker';
import { upload } from '@testing-library/user-event/dist/upload';
import { addDays } from 'date-fns';
import { isUserStaff, isUserVerified } from './Users';

export const getUploadTypeByName = async (
    uploadTypes: UploadType[],
    nameToFind: UploadTypeNames
): Promise<string | null> => {
    const foundType = uploadTypes.find((type) => type.name === nameToFind);
    return foundType ? foundType.uuid : null;
};

export const hasOpenReports = (upload: Upload, user?: User): boolean => {
    return !!(
        (getUploadStatus(upload) === UploadStatus.APPROVED ||
            getUploadStatus(upload) === UploadStatus.COMINGSOON_PUBLIC ||
            getUploadStatus(upload) === UploadStatus.COMINGSOON_PRIVATE ||
            getUploadStatus(upload) === UploadStatus.COMINGSOON_EARLYACCESS ||
            getUploadStatus(upload) === UploadStatus.EARLYACCESS) &&
        upload.pendingReports > 0 &&
        user &&
        (user.userProfile.uuid === upload.author.uuid ||
            user.userProfile.accountType !== UserAccountTypes.MEMBER)
    );
};

export const getUploadStatus = (
    upload: Upload,
    selectedVersionIndex?: number
): string => {
    if (upload.approvedAt === null && upload.declined === null) {
        return UploadStatus.PENDING;
    }

    if (upload.approvedAt) {
        let latestVersion: Version;

        if (selectedVersionIndex !== undefined) {
            latestVersion = upload.versions[selectedVersionIndex];
        } else {
            latestVersion = upload.versions[upload.versions.length - 1];
        }

        if (latestVersion.status === 'early access - scheduled') {
            return UploadStatus.COMINGSOON_EARLYACCESS;
        }

        if (latestVersion.status === 'early access') {
            return UploadStatus.EARLYACCESS;
        }

        if (latestVersion.status === 'private - scheduled') {
            return UploadStatus.COMINGSOON_PRIVATE;
        }

        if (latestVersion.status === 'public - scheduled') {
            return UploadStatus.COMINGSOON_PUBLIC;
        }

        if (
            latestVersion.status === 'private' ||
            latestVersion.status === 'public'
        ) {
            return UploadStatus.APPROVED;
        }
    }

    return UploadStatus.REJECTED;
};

export const isVersionPublic = (version: Version): boolean => {
    switch (version.status) {
        case 'public':
        case 'public - scheduled':
            return true;
        default:
            return false;
    }
};

export const isUploadPublic = (
    upload: Upload,
    selectedVersion?: number
): boolean => {
    let version: Version;

    if (selectedVersion !== undefined) {
        version = upload.versions[selectedVersion];
    } else {
        version = upload.versions[upload.versions.length - 1];
    }

    return isVersionPublic(version);
};

export const calculateTimeLeft = (
    scheduledAt: number | undefined
): TimeLeftType => {
    if (!scheduledAt) return null; // No scheduled time, return null
    const now = new Date().getTime();
    const timeDiff = scheduledAt - now;

    if (timeDiff <= 0) return null; // If the scheduled time has passed, return null

    const hours = Math.floor(timeDiff / 1000 / 3600); // Convert to hours
    const minutes = Math.floor((timeDiff % (1000 * 3600)) / 1000 / 60); // Convert remaining time to minutes
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000); // Convert remaining time to seconds

    return { hours, minutes, seconds };
};

export type TimeLeftType =
    | {
          hours: number;
          minutes: number;
          seconds: number;
      }
    | null
    | string;

export const renderTimeLeft = (timeLeft: TimeLeftType): string => {
    if (timeLeft === null) return 'Now Launching!';

    if (typeof timeLeft === 'string') {
        return 'Calculating Time';
    }

    // Handle full days (24 hours or more)
    if (timeLeft.hours >= 24) {
        const days = Math.floor(timeLeft.hours / 24); // Get full days
        return `Live in ${days} ${days === 1 ? 'day' : 'days'}`;
    }

    if (timeLeft.hours > 0) {
        return `Live in ${timeLeft.hours} ${timeLeft.hours === 1 ? 'hour' : 'hours'}`;
    } else if (timeLeft.minutes > 0) {
        return `Live in ${timeLeft.minutes} ${timeLeft.minutes === 1 ? 'minute' : 'minutes'}`;
    } else {
        return `Live in ${timeLeft.seconds} ${timeLeft.seconds === 1 ? 'second' : 'seconds'}`;
    }
};

export const removalDay = (declinedAtDate: string): string => {
    const removalDate = addDays(declinedAtDate, 1); // Ensure it's a Date object
    const today = new Date();

    // Calculate difference in days
    const daysLeft = Math.ceil(
        (removalDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
    );

    return daysLeft <= 0 ? 'Removes Today' : `Removes in ${daysLeft} Day`;
};

export const canUserAlwaysAccessUpload = (
    upload: Upload,
    user?: User,
    token?: string
) => {
    if (!token) return false;

    if (!user) return false;

    if (!isUserVerified(token, user)) return false;

    return user.userProfile.uuid === upload.author.uuid || isUserStaff(user);
};
