import axios, { AxiosProgressEvent } from 'axios';
import * as Path from 'node:path';
import exp from 'node:constants';

// TYPES
export enum UploadTypeNames {
    CHARACTER = 'character',
    GFX = 'gfx',
    CHAMPIONSHIP = 'championship',
    ARENA = 'arena',
    CHARACTER_DATA = 'character data',
    WEAPON = 'weapon',
    PROP = 'prop',
    MISC = 'misc',
    PACK = 'pack',
    MENU = 'menu',
}

export enum UploadOrderByFields {
    CREATED_AT = 'createdAt',
    NAME = 'name',
}

export enum UserAccountTypes {
    ADMIN = 'admin',
    MEMBER = 'member',
    MODERATOR = 'moderator',
}

export enum UploadStatus {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    PENDING = 'AWAITING APPROVAL',
    REPORTED = 'REPORTED',
    COMINGSOON = 'COMING SOON',
    COMINGSOON_PUBLIC = 'COMING SOON PUBLIC',
    COMINGSOON_PRIVATE = 'COMING SOON PRIVATE',
    COMINGSOON_EARLYACCESS = 'COMING SOON EARLY ACCESS',
    EARLYACCESS = 'EARLY ACCESS',
}

export enum UploadApprovalStatusRequest {
    APPROVED = 'approved',
    REJECTED = 'declined',
}

// DATA
export const UploadOrderBy: UploadOrderByTypes[] = [
    {
        field: UploadOrderByFields.CREATED_AT,
        name: 'Date Uploaded',
    },
    {
        field: UploadOrderByFields.NAME,
        name: 'Name',
    },
];

export const standardReleaseAddedDays = 3;
export const earlyAccessReleaseAddedDays = 7;

export const downloadTypeOptions: SelectedDownloadType[] = [
    {
        value: 'public',
        label: 'PUBLIC',
        caption:
            'By selecting this option, your upload will be available to the public and must be a direct download link.',
    },
    {
        value: 'private',
        label: 'PATREON EXCLUSIVE',
        caption:
            'By selecting this option, your upload will be available to verified accounts and must be a patreon post link.',
    },
    {
        value: 'both',
        label: 'EARLY ACCESS PATREON',
        caption:
            'By selecting this option, your upload will be available to verified accounts for a limited amount of time, then avaiable to the public on the selected date.',
    },
];

// INTERFACES
export interface SelectedDownloadType {
    value: 'public' | 'private' | 'both';
    label: 'PUBLIC' | 'PATREON EXCLUSIVE' | 'EARLY ACCESS PATREON';
    caption: string;
}

export interface UploadOrderByTypes {
    field: UploadOrderByFields;
    name: string;
}

export interface Meta {
    page: {
        current: number;
        previous: number | null;
        next: number | null;
        last: number;
        first: number;
    };
    items: {
        perPage: number;
        total: number;
    };
}

export interface User {
    uuid: string;
    email: string;
    verifiedAt?: string;
    ipAddress?: string;
    lastLoggedInAt?: string;
    createdAt: string;
    updatedAt: string;
    userProfile: UserProfile;
}

export interface UploadType {
    uuid: string;
    name: UploadTypeNames;
}

export interface UploadSubscription {
    uuid: string;
    subscriber: UserProfile;
    upload: Upload;
    createdAt: string;
}

export interface UserProfile {
    uuid: string;
    username: string;
    accountType: UserAccountTypes;
    paypal: string;
    patreon: string;
    facebook: string;
    twitter: string;
    youtube: string;
    description: string;
    canBypass: boolean;
    canDirectUpload: boolean;
    accountStatus: 'active' | 'suspended';
    suspended: Suspended | null;
    follows: {
        followers: number;
        following: number;
    };
    image: {
        main: string | null;
    };
    userFollowing?: boolean;
    notifications?: number;
    createdAt: string;
    updatedAt: string;
}

export interface Suspended {
    reason: string;
    by: string;
    date: string;
}

export interface Game {
    uuid: string;
    name: string;
    shortName: string;
    system: string;
    image: {
        main: string | null;
    };
    stats?: {
        hearts: number;
        downloads: number;
        views: number;
    };
}

export interface GamesGrouped {
    name: string;
    shortName: string;
    system: string[];
    uuids: string[]; // Array of UUIDs for games with the same name
    image: {
        main: string | null;
    };
    stats: {
        hearts: number;
        downloads: number;
        views: number;
    };
}

export interface Version {
    uuid: string;
    version: number;
    status:
        | 'early access - scheduled'
        | 'early access'
        | 'public - scheduled'
        | 'public'
        | 'private - scheduled'
        | 'private';
    createdAt: string;
    downloadLinkPrivate?: string;
    downloadLinkPublic?: string;
    scheduledAtPrivate?: string;
    scheduledAtPublic?: string;
}

export const isUploadHearted = (
    upload: Upload
): upload is Upload & { userHearted: boolean } => {
    return 'userHearted' in upload;
};

export const isUploadSubscribed = (
    upload: Upload
): upload is Upload & { userSubscribed: boolean } => {
    return 'userSubscribed' in upload;
};

export const isVersionMember = (
    selectedVersion: Version
): selectedVersion is Version & { downloadLinkPrivate: string } => {
    return 'downloadLinkPrivate' in selectedVersion;
};

export interface UploadImages {
    thumbnail: string | null;
    main: string | null;
    secondary: string | null;
}

export interface Stats {
    views: number;
    hearts: number;
    downloads: number;
}

export interface UploadImage {
    key: string;
}

export interface DirectUpload {
    name: string;
    description: string;
    uuid: string;
    image: string | null;
    file: string | null;
    author: UserProfile;
    createdAt: string;
    updatedAt: string;
}

export interface NotificationBase {
    uuid: string;
    category: string; // This will be extended for each specific category
    type: string;
    message: string;
    createdAt: string;
    readAt?: string;
    data?: any; // Will be defined later for each extended category
}

export interface NotificationUpload extends NotificationBase {
    category: 'upload';
    type:
        | 'upload_awaiting_approval'
        | 'upload_approved'
        | 'upload_rejected'
        | 'upload_deleted'
        | 'upload_reported'
        | 'user_profile_new_upload'
        | 'report_resolved'
        | 'upload_new_version';
    data?: Upload;
}

export interface NotificationUserProfile extends NotificationBase {
    category: 'user_profile';
    type: 'user_profile_new_follower';
    data?: UserProfile;
}

export interface NotificationUploadSubscription extends NotificationBase {
    category: 'upload_subscription';
    type: 'upload_subscription_new_follower';
    data?: UploadSubscription;
}

export type Notification =
    | NotificationUpload
    | NotificationUserProfile
    | NotificationUploadSubscription;

export interface Upload {
    uuid: string;
    name: string;
    description: string;
    miniDescription: string;
    type: UploadType;
    videoUrl?: string;
    nsfw: boolean;
    tags: string[];
    author: UserProfile;
    collaborators?: UserProfile[];
    games: Game[];
    drm: boolean;
    stats: Stats;
    versions: Version[];
    images: UploadImages;
    userHearted?: boolean;
    userSubscribed?: boolean;
    approvedAt: string | null;
    declined: Declined | null;
    deleted: Deleted | null;
    pendingReports: number;
    additionalTypeInformation?: string;
    createdAt: string;
    updatedAt: string;
    scheduledAt: string | null;
}

export interface Declined {
    declinedAt: string;
    reason: string;
}

export interface Deleted {
    deletedAt: string;
    reason: string;
}

export interface Report {
    uuid: string;
    reason: string;
    username: string;
    createdAt: string;
}

// API RESPONSES
export interface GetReportsResponse {
    data: Report[];
    message: 'Returned Reports for Upload';
    status: 200;
}

export interface PostVerifyAccountResponse {
    data: {};
    message: 'Account verified';
    status: 200;
}

export interface PostResetPasswordResponse {
    data: {};
    message: 'Password reset';
    status: 200;
}

export interface PostForgotPasswordResponse {
    data: {};
    message: 'Forgot email triggered';
    status: 200;
}

export interface PostRegisterResponse {
    data: User;
    message: 'Created user';
    status: 201;
}

export interface PostLoginResponse {
    accessToken: string;
    tokenType: 'Bearer';
    status: 200;
}

export interface GetLogoutResponse {
    message: 'Logged out';
    status: 200;
}

export interface GetUploadTypesResponse {
    data: UploadType[];
    message: 'Returned Upload Types';
    status: 200;
}

export interface GamesResponse {
    data: Game[];
    message: 'Returned Games';
    status: 200;
}

export interface PostDirectUploadResponse {
    message: 'Direct Upload Created';
    status: 201;
    data: DirectUpload;
}

export interface GetDirectUploadResponse {
    message: 'Direct Upload Returned';
    status: 200;
    data: DirectUpload;
}

export interface PutDirectUploadResponse {
    message: 'Direct Upload Updated';
    status: 200;
    data: DirectUpload;
}

export interface PostDirectUploadDiscordResponse {
    message: 'Direct File Posted to Discord';
    status: 201;
    data: DirectUpload;
}

export interface PostImageResponse {
    message: 'Image Uploaded';
    status: 201;
    data: UploadImage;
}

export interface PatchUploadResponse {
    message: 'Updated Upload';
    status: 200;
    data: Upload;
}

export interface PostUploadResponse {
    message: 'Created Upload';
    status: 201;
    data: Upload;
}

export interface PostUploadLikeResponse {
    message: 'Updated Like Data';
    status: 200;
    data: {
        success: boolean;
    };
}

export interface PostUploadSubscribeResponse {
    message: 'Updated Subscribe Data';
    status: 201;
    data: {
        success: boolean;
    };
}

export interface ReportUploadResponse {
    message: 'Reported Upload';
    status: 201;
    data: {
        success: boolean;
    };
}

export interface GetNotificationsResponse {
    message: 'Returned Notifications';
    status: 200;
    data: Notification[];
    meta: Meta;
}

export interface DeleteNotificationResponse {
    message: 'Notification Read';
    status: 204;
    data: {
        success: boolean;
    };
}

export interface DeleteAllNotificationsResponse {
    message: 'All Notifications Read';
    status: 204;
    data: {
        success: boolean;
    };
}

export interface PostUploadApprovalResponse {
    message: 'Updated Approval Status';
    status: 200;
    data: Upload;
}

export interface PostUserProfileFollowResponse {
    message: 'Updated Following User Profile Data';
    status: 200;
    data: {
        success: boolean;
    };
}

export interface DeleteUploadResponse {
    message: 'Upload Deleted';
    status: 204;
    data: {
        success: boolean;
    };
}

export interface GetUploadVersionResponse {
    message: 'Updated Download Data';
    status: 200;
    data: {
        downloadLink: string;
    };
}

export interface GetUploadResponse {
    message: 'Returned Upload';
    status: 200;
    data: Upload;
}

export interface GetUploadsResponse {
    message: 'Returned Uploads';
    status: 200;
    data: Upload[];
    meta: Meta;
}

export interface GetFooterStatsResponse {
    message: 'Returned footer stats';
    status: 200;
    data: {
        users: number;
        uploads: number;
        hearts: number;
    };
}

export interface PostUploadVersionResponse {
    message: 'Added new version';
    status: 201;
    data: Upload;
}

export interface SuspendUserProfileResponse {
    message: string;
    status: 201;
    data: {
        success: string[];
        failed: string[];
        alreadySuspended: string[];
    };
}

export interface ResendVerificationResponse {
    message: string;
    status: 201;
    data: {};
}

export interface UnsuspendUserProfileResponse {
    message: string;
    status: 201;
    data: {
        success: string[];
        failed: string[];
        alreadyUnsuspended: string[];
    };
}

export interface UpdateUserProfileResponse {
    message: string;
    status: 200;
    data: UserProfile;
}

export interface GetUserResponse {
    message: string;
    status: 200;
    data: User;
}

export interface GetUsersResponse {
    message: string;
    status: 200;
    data: User[];
    meta: Meta;
}

export interface GetUserProfilesResponse {
    message: string;
    status: 200;
    data: UserProfile[];
    meta: Meta;
}

export interface PutReportResponse {
    message: 'Report Updated';
    status: 200;
    data: Upload;
}

// API REQUESTS GET
export interface GetUsersParams {
    count?: number;
    page?: number;
    username?: string;
    uuid?: string;
}

export interface GetUserProfilesParams {
    count?: number;
    page?: number;
    username?: string;
    uuid?: string;
}

export interface GetUploadsParams {
    count?: number;
    page?: number;
    name?: string;
    type?: string;
    additionalTypeInformation?: string;
    status?: string;
    tag?: string;
    userProfile?: string;
    orderBy?: UploadOrderByFields;
    gamesArray?: string[];
    games?: string;
}

export interface GetNotificationsParams {
    count: number;
    page: number;
}

export interface GetUploadParams {
    uuid: string;
}

export interface GetUploadVersionLinkParams {
    uuid: string;
    versionUuid: string;
}

// API REQUESTS POST PUT PATCH
export interface DeleteUploadProps {
    reason: string;
}

export interface UpdateReportProps {
    response: string;
}

export interface SuspendUserProfileProps {
    uuids: string[];
    reason: string;
}

export interface UnsuspendUserProfileProps {
    uuids: string[];
    reason: string;
}

export interface PostUploadVersionProps {
    downloadLinkPrivate?: string;
    downloadLinkPublic?: string;
    scheduledAtPublic?: string;
    scheduledAtPrivate?: string;
    selectedDownloadLinkType?: 'public' | 'private' | 'both';
}

export interface UpdateUserProps {
    username?: string;
    imageMain?: string;
    twitter?: string;
    paypal?: string;
    youtube?: string;
    facebook?: string;
    patreon?: string;
    description?: string;
}

export interface UpdateUploadProps {
    name?: string;
    videoUrl?: string;
    miniDescription?: string;
    description?: string;
    tags?: string[];
    imageMain?: string;
}

export interface UpdateUploadBasicProps {
    name: string;
    videoUrl?: string;
    miniDescription: string;
    description: string;
    tags: string[];
    games: string[];
}

export interface CreateUploadBasicDetailsProps {
    uuid?: string;
    imageMain?: string;
    name: string;
    uploadType: string;
    videoUrl?: string;
    miniDescription: string;
    description: string;
    games: string[];
}

export interface CreateUploadFinalizeProps {
    collaborators: string[];
    downloadLinkPrivate?: string;
    downloadLinkPublic?: string;
    additionalTypeInformation?: string;
    tags: string[];
    drm: boolean;
    nsfw: boolean;
    scheduledAtPublic?: string;
    scheduledAtPrivate?: string;
    selectedDownloadLinkType?: 'public' | 'private' | 'both';
}

export interface CreateUploadProps
    extends CreateUploadBasicDetailsProps,
        CreateUploadFinalizeProps {}

export interface PostUploadApprovalBase {
    status: UploadApprovalStatusRequest;
}

export interface PostUploadApprovalRejected extends PostUploadApprovalBase {
    status: UploadApprovalStatusRequest.REJECTED;
    reason: string;
}

export interface PostUploadApprovalApproved extends PostUploadApprovalBase {
    status: UploadApprovalStatusRequest.APPROVED;
    reason?: never; // Disallow 'reason' for APPROVED status
}

export type PostUploadApprovalProps =
    | PostUploadApprovalApproved
    | PostUploadApprovalRejected;

export interface ReportUploadProps {
    reason: string;
}

export interface AddImageProps {
    uuid: string;
    image: File;
    type: string;
    scope: string;
}

export interface PostDirectUploadProps {
    name: string;
    description: string;
    uuid: string;
}

export interface PostDirectUploadFileProps {
    type: 'image' | 'file';
    file: File;
}

export interface FileUploaderProps {
    title: string;
    description: string;
    image?: string;
    file?: string;
}

// API CALLS
export const postDirectUpload = async (
    data: PostDirectUploadProps,
    authToken: string
): Promise<PostDirectUploadResponse> => {
    let url = process.env.REACT_APP_POST_UPLOAD_DIRECT as string;

    const fetchResult = await axios.post(url, data, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });

    return fetchResult.data as PostDirectUploadResponse;
};

export const postDirectUploadFile = async (
    data: PostDirectUploadFileProps,
    uuid: string,
    authToken: string
): Promise<PutDirectUploadResponse> => {
    let url = process.env.REACT_APP_POST_UPLOAD_DIRECT_FILE as string;
    url = url.replace(':uuid', uuid);

    const formData = new FormData();
    formData.append('type', data.type);
    formData.append('file', data.file);

    const fetchResult = await axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
        },
    });
    return fetchResult.data as PutDirectUploadResponse;
};

export const deleteUpload = async (
    uuid: string,
    authToken: string,
    data: DeleteUploadProps
): Promise<DeleteUploadResponse> => {
    let url = process.env.REACT_APP_DELETE_UPLOAD as string;
    url = url.replace(':uuid', uuid);

    const fetchResult = await axios.delete(url, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
        data,
    });

    return fetchResult.data as DeleteUploadResponse;
};

export const postDirectUploadDiscord = async (
    uuid: string,
    authToken: string
): Promise<PostDirectUploadDiscordResponse> => {
    let url = process.env.REACT_APP_POST_UPLOAD_DIRECT_DISCORD as string;
    url = url.replace(':uuid', uuid);

    const fetchResult = await axios.post(
        url,
        {},
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        }
    );

    return fetchResult.data as PostDirectUploadDiscordResponse;
};

export const postImage = async (
    data: AddImageProps,
    authToken: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
): Promise<PostImageResponse> => {
    let url = process.env.REACT_APP_POST_UPLOAD_IMAGE as string;
    url = url.replace(':uuid', data.uuid);

    const formData = new FormData();
    formData.append('type', data.type);
    formData.append('image', data.image);
    formData.append('scope', data.scope);

    const fetchResult = await axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
        },
        onUploadProgress,
    });
    return fetchResult.data as PostImageResponse;
};

export const updateUpload = async (
    data: UpdateUploadProps,
    authToken: string,
    uuid: string
): Promise<PatchUploadResponse> => {
    let url = process.env.REACT_APP_PATCH_UPLOAD as string;
    url = url.replace(':uuid', uuid);

    const fetchResult = await axios.patch(url, data, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });

    return fetchResult.data as PatchUploadResponse;
};

export const postUpload = async (
    data: CreateUploadProps,
    authToken: string,
    uuid: string,
    imageMain: string,
    uploadType?: UploadType
): Promise<PostUploadResponse> => {
    const url = process.env.REACT_APP_GET_UPLOADS as string;

    if (uploadType) {
        if (
            !(
                uploadType.name === 'character' ||
                uploadType.name === 'character data'
            )
        ) {
            data.additionalTypeInformation = undefined;
        }
    }

    if (data.videoUrl === '') {
        data.videoUrl = undefined;
    }

    data.uuid = uuid;
    data.imageMain = imageMain;

    switch (data.selectedDownloadLinkType) {
        case 'public':
            data.downloadLinkPrivate = undefined;
            data.scheduledAtPrivate = undefined;
            break;
        case 'private':
            data.downloadLinkPublic = undefined;
            data.scheduledAtPublic = undefined;
            break;
    }

    if (data.downloadLinkPrivate === null) {
        data.downloadLinkPrivate = undefined;
    }

    if (data.downloadLinkPublic === null) {
        data.downloadLinkPublic = undefined;
    }

    if (data.scheduledAtPublic === null) {
        data.scheduledAtPublic = undefined;
    }

    if (data.scheduledAtPrivate === null) {
        data.scheduledAtPrivate = undefined;
    }

    const fetchResult = await axios.post(url, data, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });

    return fetchResult.data as PostUploadResponse;
};

export const getNotifications = async (
    authToken: string,
    params: GetNotificationsParams
): Promise<GetNotificationsResponse> => {
    // const url = process.env.REACT_APP_GET_UPLOADS as string;
    const url = process.env.REACT_APP_GET_USERPROFILE_NOTIFICATIONS as string;

    const fetchResult = await axios.get(url, {
        params: params,
        ...(authToken && { headers: { Authorization: `Bearer ${authToken}` } }),
    });

    return fetchResult.data as GetNotificationsResponse;
};

export const deleteNotification = async (
    uuid: string,
    authToken: string
): Promise<DeleteNotificationResponse> => {
    let url = process.env.REACT_APP_DELETE_USERPROFILE_NOTIFICATION as string;
    url = url.replace(':uuid', uuid);

    const fetchResult = await axios.delete(url, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });

    return fetchResult.data as DeleteNotificationResponse;
};

export const deleteAllNotifications = async (
    authToken: string
): Promise<DeleteAllNotificationsResponse> => {
    let url = process.env.REACT_APP_DELETE_USERPROFILE_NOTIFICATIONS as string;

    const fetchResult = await axios.delete(url, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });

    return fetchResult.data as DeleteAllNotificationsResponse;
};

export const getUpload = async (
    params: GetUploadParams,
    authToken?: string
): Promise<GetUploadResponse> => {
    // const url = process.env.REACT_APP_GET_UPLOADS as string;
    let url = process.env.REACT_APP_GET_UPLOAD as string;
    url = url.replace(':uuid', params.uuid);

    const fetchResult = await axios.get(url, {
        params: params || {},
        ...(authToken && { headers: { Authorization: `Bearer ${authToken}` } }),
    });

    return fetchResult.data as GetUploadResponse;
};

export const postUserProfileFollow = async (
    uuid: string,
    authToken: string
): Promise<PostUserProfileFollowResponse> => {
    // const url = process.env.REACT_APP_GET_UPLOADS as string;
    let url = process.env.REACT_APP_GET_USERPROFILE_FOLLOWS as string;
    url = url.replace(':uuid', uuid);

    const fetchResult = await axios.post(
        url,
        {},
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        }
    );

    return fetchResult.data as PostUserProfileFollowResponse;
};

export const reportUpload = async (
    uuid: string,
    authToken: string,
    data: ReportUploadProps
): Promise<ReportUploadResponse> => {
    let url = process.env.REACT_APP_POST_UPLOAD_REPORT as string;
    url = url.replace(':uuid', uuid);

    const fetchResult = await axios.post(url, data, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });

    return fetchResult.data as ReportUploadResponse;
};

export const postUploadApproval = async (
    uuid: string,
    authToken: string,
    data: PostUploadApprovalProps
): Promise<PostUploadApprovalResponse> => {
    let url = process.env.REACT_APP_POST_UPLOAD_APPROVAL as string;
    url = url.replace(':uuid', uuid);

    const fetchResult = await axios.post(url, data, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });

    return fetchResult.data as PostUploadApprovalResponse;
};

export const postUploadSubscribe = async (
    uuid: string,
    authToken: string
): Promise<PostUploadSubscribeResponse> => {
    let url = process.env.REACT_APP_POST_UPLOAD_SUBSCRIBE as string;
    url = url.replace(':uuid', uuid);

    const fetchResult = await axios.post(
        url,
        {},
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        }
    );

    return fetchResult.data as PostUploadSubscribeResponse;
};

export const postUploadLike = async (
    uuid: string,
    authToken: string
): Promise<PostUploadLikeResponse> => {
    // const url = process.env.REACT_APP_GET_UPLOADS as string;
    let url = process.env.REACT_APP_POST_UPLOAD_LIKE as string;
    url = url.replace(':uuid', uuid);

    const fetchResult = await axios.post(
        url,
        {},
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        }
    );

    return fetchResult.data as PostUploadLikeResponse;
};

export const getUploadVersionLink = async (
    params: GetUploadVersionLinkParams,
    authToken?: string
): Promise<GetUploadVersionResponse> => {
    // const url = process.env.REACT_APP_GET_UPLOADS as string;
    let url = process.env.REACT_APP_GET_UPLOAD_VERSION_LINK as string;
    url = url.replace(':uuid', params.uuid);
    url = url.replace(':versionUuid', params.versionUuid);

    const fetchResult = await axios.get(url, {
        ...(authToken && { headers: { Authorization: `Bearer ${authToken}` } }),
    });

    return fetchResult.data as GetUploadVersionResponse;
};

export const getMyUploads = async (
    authToken: string,
    params?: GetUploadsParams
): Promise<GetUploadsResponse> => {
    const newRequest = { ...params };

    delete newRequest?.userProfile;

    const url = process.env.REACT_APP_GET_UPLOADS_BY_USERS as string;

    return await getUploadsCore(url, authToken, newRequest);
};

export const getUploads = async (
    authToken?: string,
    params?: GetUploadsParams
): Promise<GetUploadsResponse> => {
    let url = process.env.REACT_APP_GET_UPLOADS as string;

    return await getUploadsCore(url, authToken, params);
};

export const getUploadsDirection = async (
    myUploads?: boolean,
    authToken?: string,
    params?: GetUploadsParams
): Promise<GetUploadsResponse> => {
    if (myUploads) {
        if (authToken) {
            return await getMyUploads(authToken, params);
        } else {
            return await getUploads(authToken, params);
        }
    } else {
        return await getUploads(authToken, params);
    }
};

export const getUploadsCore = async (
    url: string,
    authToken?: string,
    params?: GetUploadsParams
): Promise<GetUploadsResponse> => {
    const newRequest = { ...params };

    if (newRequest) {
        if (newRequest.gamesArray) {
            if (newRequest.gamesArray.length > 0) {
                newRequest.games = newRequest.gamesArray.join(',');
                delete newRequest.gamesArray;
            } else {
                delete newRequest.games;
            }
        }

        if (newRequest.name === '') {
            delete newRequest.name;
        }

        if (newRequest.type === 'all') {
            delete newRequest.type;
        }
    }

    const fetchResult = await axios.get(url, {
        params: newRequest || {},
        ...(authToken && { headers: { Authorization: `Bearer ${authToken}` } }),
    });

    return fetchResult.data as GetUploadsResponse;
};

export const getUploadTypes = async (): Promise<GetUploadTypesResponse> => {
    const url = process.env.REACT_APP_GET_UPLOAD_TYPES as string;

    const fetchResult = await axios.get(url);

    return fetchResult.data as GetUploadTypesResponse;
};

export const getUserMe = async (
    authToken: string
): Promise<GetUserResponse> => {
    const url = process.env.REACT_APP_GET_USERS_ME as string;

    const fetchResult = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });

    return fetchResult.data as GetUserResponse;
};

export const getReportsByUpload = async (
    uuid: string,
    authToken: string
): Promise<GetReportsResponse> => {
    let url = process.env.REACT_APP_GET_UPLOAD_REPORTS as string;
    url = url.replace(':uuid', uuid);

    const fetchResult = await axios.get(url, {
        ...(authToken && { headers: { Authorization: `Bearer ${authToken}` } }),
    });

    return fetchResult.data as GetReportsResponse;
};

export const getUsers = async (
    params?: GetUsersParams
): Promise<GetUsersResponse> => {
    const url = process.env.REACT_APP_GET_USERS as string;

    const fetchResult = await axios.get(url, {
        params: params || {},
    });

    return fetchResult.data as GetUsersResponse;
};

export const getFooterStats = async (): Promise<GetFooterStatsResponse> => {
    const url = process.env.REACT_APP_GET_FOOTER_STATS as string;

    const fetchResult = await axios.get(url);

    return fetchResult.data as GetFooterStatsResponse;
};

export const getUserProfiles = async (
    params?: GetUserProfilesParams,
    authToken?: string
): Promise<GetUserProfilesResponse> => {
    const url = process.env.REACT_APP_GET_USER_PROFILES as string;

    const fetchResult = await axios.get(url, {
        params: params || {},
        ...(authToken && { headers: { Authorization: `Bearer ${authToken}` } }),
    });

    return fetchResult.data as GetUserProfilesResponse;
};

export const updateReport = async (
    data: UpdateReportProps,
    authToken: string,
    uploadUuid: string,
    reportUuid: string
): Promise<PutReportResponse> => {
    let url = process.env.REACT_APP_PATCH_UPLOAD_REPORT as string;
    url = url.replace(':uuid', uploadUuid);
    url = url.replace(':reportUuid', reportUuid);

    const fetchResult = await axios.patch(url, data, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });

    return fetchResult.data as PutReportResponse;
};

export const updateUserProfile = async (
    data: UpdateUserProps,
    authToken: string
): Promise<UpdateUserProfileResponse> => {
    const url = process.env.REACT_APP_PATCH_API_USERPROFILE as string;
    const fetchResult = await axios.patch(url, data, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return fetchResult.data as UpdateUserProfileResponse;
};

export const postUploadVersion = async (
    data: PostUploadVersionProps,
    authToken: string,
    uuid: string
): Promise<PostUploadVersionResponse> => {
    let url = process.env.REACT_APP_POST_UPLOAD_VERSION as string;

    url = url.replace(':uuid', uuid);

    switch (data.selectedDownloadLinkType) {
        case 'public':
            data.downloadLinkPrivate = undefined;
            data.scheduledAtPrivate = undefined;
            break;
        case 'private':
            data.downloadLinkPublic = undefined;
            data.scheduledAtPublic = undefined;
            break;
    }

    if (data.downloadLinkPrivate === null) {
        data.downloadLinkPrivate = undefined;
    }

    if (data.downloadLinkPublic === null) {
        data.downloadLinkPublic = undefined;
    }

    if (data.scheduledAtPublic === null) {
        data.scheduledAtPublic = undefined;
    }

    if (data.scheduledAtPrivate === null) {
        data.scheduledAtPrivate = undefined;
    }

    const fetchResult = await axios.post(url, data, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });

    return fetchResult.data as PostUploadVersionResponse;
};

export const suspendUserProfiles = async (
    data: SuspendUserProfileProps,
    authToken: string
): Promise<SuspendUserProfileResponse> => {
    const url = process.env.REACT_APP_PATCH_API_USERPROFILE_SUSPEND as string;
    const fetchResult = await axios.post(url, data, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return fetchResult.data as SuspendUserProfileResponse;
};

export const resendVerification = async (
    authToken: string
): Promise<ResendVerificationResponse> => {
    const url = process.env.REACT_APP_POST_USERS_VERIFY_RESEND as string;
    const fetchResult = await axios.post(
        url,
        {},
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        }
    );
    return fetchResult.data as ResendVerificationResponse;
};

export const unsuspendUserProfiles = async (
    data: SuspendUserProfileProps,
    authToken: string
): Promise<UnsuspendUserProfileResponse> => {
    const url = process.env.REACT_APP_PATCH_API_USERPROFILE_UNSUSPEND as string;
    const fetchResult = await axios.post(url, data, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return fetchResult.data as UnsuspendUserProfileResponse;
};

export const verifyAccount = async (
    authToken: string,
    verifyToken: string
): Promise<PostVerifyAccountResponse> => {
    const url = process.env.REACT_APP_POST_USERS_VERIFY as string;

    console.log(url, verifyToken);

    const fetchResult = await axios.post(
        url,
        {
            token: verifyToken,
        },
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        }
    );
    return fetchResult.data as PostVerifyAccountResponse;
};

export const resetPassword = async (
    token: string,
    password: string,
    captchaValue: string
): Promise<PostResetPasswordResponse> => {
    const url = process.env.REACT_APP_POST_USERS_RESET_PASSWORD as string;
    const fetchResult = await axios.post(url, {
        token: token,
        password: password,
        captcha: captchaValue,
    });
    return fetchResult.data as PostResetPasswordResponse;
};

export const forgotPassword = async (
    email: string,
    captchaValue: string
): Promise<PostForgotPasswordResponse> => {
    const url = process.env.REACT_APP_POST_USERS_FORGOT_PASSWORD as string;
    const fetchResult = await axios.post(url, {
        email: email,
        captcha: captchaValue,
    });
    return fetchResult.data as PostForgotPasswordResponse;
};

export const createUser = async (
    email: string,
    password: string,
    username: string,
    captchaValue: string
): Promise<PostRegisterResponse> => {
    const url = process.env.REACT_APP_POST_AUTH_USERS as string;
    const fetchResult = await axios.post(url, {
        email: email,
        password: password,
        username: username,
        captcha: captchaValue,
    });
    return fetchResult.data as PostRegisterResponse;
};

export const login = async (
    email: string,
    password: string,
    captchaValue: string
): Promise<PostLoginResponse> => {
    const url = process.env.REACT_APP_POST_AUTH_LOGIN as string;
    const fetchResult = await axios.post(url, {
        email: email,
        password: password,
        captcha: captchaValue,
    });
    return fetchResult.data as PostLoginResponse;
};

export const logout = async (authToken: string): Promise<GetLogoutResponse> => {
    const url = process.env.REACT_APP_POST_AUTH_LOGOUT as string;
    const fetchResult = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return fetchResult.data as GetLogoutResponse;
};

export const getGames = async (stats?: boolean): Promise<GamesResponse> => {
    const url = process.env.REACT_APP_GET_GAMES as string;

    // Construct params based on the stats argument
    const params = stats ? { includes: 'stats' } : {};

    // Fetch the games data with the conditional params
    const fetchResult = await axios.get(url, {
        params: params,
    });

    return fetchResult.data as GamesResponse;
};
